export const NAVIGATION_ITEMS = [
  { label: 'Services', href: '#services' },
  { label: 'Portfolio', href: '#portfolio' },
  { label: 'About', href: '#about' },
  { label: 'Testimonials', href: '#testimonials' },
];

export const TECH_STACK = [
  'Flutter',
  'Flutter Flow',
  'Bubble.io',
  'UX/UI Design',
  'WebFlow',
  'Vue.js',
  'Node.js',
  'Express.js',
  'React Native',
  'MongoDB',
  'Firebase',
  '.NET'
];