import { motion } from 'framer-motion';
import { Code2, Rocket, Workflow, Zap } from 'lucide-react';
import { useLanguage } from '../contexts/LanguageContext';
import { translations } from '../translations';

interface ServicesProps {
  id?: string;
}

export const Services = ({ id }: ServicesProps) => {
  const { language } = useLanguage();
  const t = translations[language];

  const services = [
    {
      icon: Rocket,
      title: t.services.mvp.title,
      description: t.services.mvp.description,
      gradientFrom: 'from-[#1B1464]/20',
      gradientTo: 'to-[#1B1464]/5',
      iconColor: 'text-blue-400',
      // path: '/mvp-design' // Commented out for future use
    },
    {
      icon: Zap,
      title: t.services.noCode.title,
      description: t.services.noCode.description,
      gradientFrom: 'from-[#1E3A8A]/20',
      gradientTo: 'to-[#1E3A8A]/5',
      iconColor: 'text-blue-400',
      // path: '/no-code-development' // Commented out for future use
    },
    {
      icon: Code2,
      title: t.services.fullStack.title,
      description: t.services.fullStack.description,
      gradientFrom: 'from-[#064E3B]/20',
      gradientTo: 'to-[#064E3B]/5',
      iconColor: 'text-emerald-400',
      // path: '/full-stack-development' // Commented out for future use
    },
    {
      icon: Workflow,
      title: t.services.automation.title,
      description: t.services.automation.description,
      gradientFrom: 'from-[#3730A3]/20',
      gradientTo: 'to-[#3730A3]/5',
      iconColor: 'text-indigo-400',
      // path: '/automation' // Commented out for future use
    },
  ];

  return (
    <section id={id} className="py-16 sm:py-24 bg-transparent scroll-mt-20">
      <div className="container mx-auto px-4">
        <motion.h2
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="text-3xl font-bold text-white text-center mb-16"
        >
          Our Services
        </motion.h2>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          {services.map((service, index) => {
            const Icon = service.icon;
            return (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.1 }}
                className={`
                  relative group rounded-2xl overflow-hidden backdrop-blur-sm
                  bg-gradient-to-b ${service.gradientFrom} ${service.gradientTo}
                  border border-white/10 hover:border-white/20 transition-colors
                `}
              >
                <div className="p-6">
                  <div className={`${service.iconColor} p-3 bg-white/5 rounded-xl inline-block mb-4`}>
                    <Icon className="w-6 h-6" />
                  </div>
                  <h3 className="text-lg font-semibold text-white mb-2">
                    {service.title}
                  </h3>
                  <p className="text-[#DADADA] text-sm leading-relaxed">
                    {service.description}
                  </p>
                </div>
              </motion.div>
            );
          })}
        </div>
      </div>
    </section>
  );
};