import { motion } from 'framer-motion';
import { useLanguage } from '../contexts/LanguageContext';
import { translations } from '../translations';

export const FlutterFlowShowcase = () => {
  const { language } = useLanguage();
  const t = translations[language];

  return (
    <section className="relative py-24">
      <div className="container mx-auto px-4">
        <motion.h2
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="text-2xl sm:text-3xl font-bold text-white text-center lg:text-left mb-8 lg:mb-0"
        >
          {t.flutterFlow.title}
        </motion.h2>

        <div className="flex flex-col lg:flex-row items-center gap-8 lg:gap-12">
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8, delay: 0.2 }}
            className="w-full lg:w-1/2 order-1"
          >
            <div className="relative">
              <div className="absolute -inset-0.5 bg-gradient-to-r from-purple-500/30 to-pink-500/30 rounded-2xl blur-xl opacity-50" />
              <img 
                src="/images/flutterflow.png" 
                alt="FlutterFlow Interface" 
                className="relative rounded-2xl shadow-2xl w-full border border-white/10"
              />
            </div>
          </motion.div>

          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8, delay: 0.4 }}
            className="w-full lg:w-1/2 order-2"
          >
            <p className="text-[#DADADA] text-sm sm:text-base lg:text-lg mb-6 lg:mb-8 leading-relaxed text-center lg:text-left">
              {t.flutterFlow.description}
            </p>
          </motion.div>
        </div>
      </div>
    </section>
  );
};