import { motion } from 'framer-motion';
import { useLanguage } from '../contexts/LanguageContext';
import { Globe } from 'lucide-react';
import { useState } from 'react';

const languages = [
  { code: 'en', label: 'English' },
  { code: 'de', label: 'Deutsch' },
  { code: 'pt', label: 'Português' }
] as const;

export const LanguageSelector = () => {
  const { language, setLanguage } = useLanguage();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="relative">
      <motion.button
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center gap-2 bg-white/10 px-3 py-1.5 rounded-full text-xs text-white hover:bg-white/20 transition-colors"
      >
        <Globe className="w-3.5 h-3.5" />
        <span>{languages.find(lang => lang.code === language)?.label}</span>
      </motion.button>

      {isOpen && (
        <motion.div
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 10 }}
          className="absolute top-full right-0 mt-2 bg-[#1A1A1A] border border-white/10 rounded-lg shadow-xl py-1 min-w-[120px]"
        >
          {languages.map((lang) => (
            <button
              key={lang.code}
              onClick={() => {
                setLanguage(lang.code);
                setIsOpen(false);
              }}
              className={`w-full px-4 py-2 text-left text-xs hover:bg-white/10 transition-colors ${
                language === lang.code ? 'text-white' : 'text-white/70'
              }`}
            >
              {lang.label}
            </button>
          ))}
        </motion.div>
      )}
    </div>
  );
};