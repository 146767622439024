import { motion } from 'framer-motion';
import { ExternalLink } from 'lucide-react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';

interface PortfolioProps {
  id?: string;
}

const projects = [
  {
    title: 'Ist das Keto?',
    description: "The ultimate companion for your ketogenic lifestyle. We designed it to support you in every step of your keto journey with macro tracking, meal planning, and progress monitoring.",
    image: '/images/keto.png',
    website: 'https://ist-das-keto.com'
  },
  {
    title: 'Ooder',
    description: "Compare grocery prices across major supermarkets. Save favorites, read reviews, and enjoy a more efficient, cost-effective shopping experience with Ooder.",
    image: '/images/ooder.jpg',
    website: 'https://ooder.co.uk'
  },
  {
    title: 'PEOPAYGO',
    description: "Online payroll and workers compensation insurance through PEO services. Bundle payroll, HR, and small business functions to save time and money.",
    image: '/images/peopaygo.jpg',
    stores: {
      google: 'https://play.google.com/store/apps/details?id=com.application.ppg&hl=en&pli=1',
      apple: 'https://apps.apple.com/us/app/peopaygo-mobile-app/id1644529287'
    }
  },
  {
    title: 'Maplife',
    description: "A social medical app connecting people with similar health conditions, offering emergency alerts for timely assistance and support.",
    image: '/images/maplife.jpg',
    stores: {
      google: 'https://play.google.com/store/apps/details?id=com.bit4code.maplife',
      apple: 'https://apps.apple.com/sa/app/maplife/id6451362863'
    }
  },
  {
    title: 'OCMI Workers Comp',
    description: "Pay-As-You-Go PEO programs in Florida providing control, visibility, and cost savings for your business.",
    image: '/images/ocmi.jpg',
    stores: {
      google: 'https://play.google.com/store/apps/details?id=com.companyapp.ocmiapp&hl=en_US',
      apple: 'https://apps.apple.com/us/app/ocmi-workers-comp/id1673809555'
    }
  }
];

export const Portfolio = ({ id }: PortfolioProps) => {
  return (
    <section id={id} className="py-24 relative overflow-hidden scroll-mt-20">
      <div className="container mx-auto px-4">
        <motion.h2
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="text-3xl font-bold text-white text-center mb-16"
        >
          Some of Our Projects
        </motion.h2>

        <Swiper
          modules={[Pagination, Autoplay]}
          spaceBetween={32}
          slidesPerView={1}
          pagination={{ clickable: true }}
          autoplay={{ delay: 5000 }}
          className="portfolio-swiper"
          breakpoints={{
            640: { slidesPerView: 2 },
            1024: { slidesPerView: 3 }
          }}
        >
          {projects.map((project, index) => (
            <SwiperSlide key={project.title}>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.1 }}
                className="bg-white/5 backdrop-blur-lg rounded-2xl overflow-hidden border border-white/10 group hover:border-purple-500/50 transition-colors h-full"
              >
                <div className="relative aspect-[16/9] overflow-hidden">
                  <img
                    src={project.image}
                    alt={project.title}
                    className="w-full h-full object-cover object-center group-hover:scale-105 transition-transform duration-500"
                  />
                </div>
                <div className="p-6 flex flex-col min-h-[200px]">
                  <h3 className="text-xl font-semibold text-white mb-2">{project.title}</h3>
                  <p className="text-[#DADADA] text-sm mb-4 flex-grow">{project.description}</p>
                  <div className="flex flex-wrap gap-2 mt-auto">
                    {project.website && (
                      <a
                        href={project.website}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="inline-flex items-center gap-1 text-xs text-white bg-white/10 px-3 py-1 rounded-full hover:bg-white/20 transition-colors"
                      >
                        Visit Website <ExternalLink className="w-3 h-3" />
                      </a>
                    )}
                    {project.stores?.google && (
                      <a
                        href={project.stores.google}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="inline-flex items-center gap-1 text-xs text-white bg-white/10 px-3 py-1 rounded-full hover:bg-white/20 transition-colors"
                      >
                        Google Play
                      </a>
                    )}
                    {project.stores?.apple && (
                      <a
                        href={project.stores.apple}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="inline-flex items-center gap-1 text-xs text-white bg-white/10 px-3 py-1 rounded-full hover:bg-white/20 transition-colors"
                      >
                        App Store
                      </a>
                    )}
                  </div>
                </div>
              </motion.div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
};