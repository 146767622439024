import { motion } from 'framer-motion';

const technologies = [
  'Flutter', 'Flutter Flow', 'Bubble.io', 'UX/UI Design',
  'WebFlow', 'Vue.js', 'Node.js', 'Express.js',
  'React Native', 'MongoDB', 'Firebase', '.NET'
];

export const TechStack = () => {
  return (
    <section className="py-24 bg-transparent">
      <div className="container mx-auto px-4">
        <motion.h2
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="text-3xl font-bold text-white text-center mb-16"
        >
          Tools & Tech We Love
        </motion.h2>
        <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-6">
          {technologies.map((tech, index) => (
            <motion.div
              key={tech}
              initial={{ opacity: 0, scale: 0.8 }}
              whileInView={{ opacity: 1, scale: 1 }}
              viewport={{ once: true }}
              transition={{ delay: index * 0.1 }}
              whileHover={{ scale: 1.1 }}
              className="bg-gray-900/50 p-4 rounded-lg border border-gray-800 text-center hover:border-purple-500/50 transition-colors backdrop-blur-sm"
            >
              <span className="text-[#DADADA] text-sm">{tech}</span>
            </motion.div>
          ))}
        </div>
        <p className="text-center text-[#DADADA] text-sm mt-8">
          We pick the right tools for the job—no fluff, all function.
        </p>
      </div>
    </section>
  );
};