import { motion } from 'framer-motion';
import { MessageSquare, Menu, X } from 'lucide-react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { NAVIGATION_ITEMS } from '../utils/constants';
import { LanguageSelector } from './LanguageSelector';
import { useLanguage } from '../contexts/LanguageContext';
import { translations } from '../translations';

export const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { language } = useLanguage();
  const t = translations[language];
  const navigate = useNavigate();

  const handleNavClick = (href: string) => {
    setIsMenuOpen(false);
    const element = document.querySelector(href);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleTalkToUs = () => {
    setIsMenuOpen(false);
    navigate('/talk-to-us');
  };

  return (
    <>
      <div className="fixed top-4 left-0 right-0 z-50 flex justify-center px-4">
        <motion.nav 
          initial={{ y: -20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          className="bg-[#0A0A0A]/80 backdrop-blur-md border border-white/10 rounded-full w-full max-w-4xl"
        >
          <div className="px-4 sm:px-8 h-12 sm:h-14 flex items-center justify-between">
            <div className="flex items-center gap-6 sm:gap-10">
              <img 
                src="/images/logo.png" 
                alt="Willow Wisp Logo" 
                className="h-8 sm:h-10 w-auto"
              />
              <div className="hidden md:flex items-center gap-6 sm:gap-8">
                {NAVIGATION_ITEMS.map((item) => (
                  <button
                    key={item.label}
                    onClick={() => handleNavClick(item.href)}
                    className="text-[#DADADA] hover:text-white text-[11px] sm:text-xs transition-colors"
                  >
                    {t.nav[item.label.toLowerCase() as keyof typeof t.nav]}
                  </button>
                ))}
              </div>
            </div>
            <div className="flex items-center gap-6">
              <LanguageSelector />
              <button 
                onClick={handleTalkToUs}
                className="bg-gradient-to-r from-purple-500 to-pink-500 text-white text-[10px] sm:text-xs px-3 sm:px-5 py-1.5 sm:py-2 rounded-full flex items-center gap-1.5 sm:gap-2 hover:opacity-90 transition-opacity shadow-lg shadow-purple-500/25"
              >
                <MessageSquare className="w-3 h-3 sm:w-3.5 sm:h-3.5" />
                <span className="hidden sm:inline">{t.nav.talkToUs}</span>
                <span className="sm:hidden">{t.nav.contact}</span>
              </button>
              <button 
                onClick={() => setIsMenuOpen(true)}
                className="md:hidden text-white p-1"
              >
                <Menu className="w-4 h-4 sm:w-5 sm:h-5" />
              </button>
            </div>
          </div>
        </motion.nav>
      </div>

      {/* Mobile Menu */}
      <motion.div 
        initial={{ x: '100%' }}
        animate={{ x: isMenuOpen ? '0%' : '100%' }}
        transition={{ type: 'tween' }}
        className="fixed inset-0 z-50 bg-[#0A0A0A] md:hidden"
      >
        <div className="flex flex-col h-full">
          <div className="flex justify-between items-center p-6">
            <img 
              src="/images/logo.png" 
              alt="Willow Wisp Logo" 
              className="h-8 w-auto"
            />
            <button 
              onClick={() => setIsMenuOpen(false)}
              className="text-white p-1"
            >
              <X className="w-6 h-6" />
            </button>
          </div>
          <div className="flex flex-col items-center gap-8 pt-12">
            <LanguageSelector />
            {NAVIGATION_ITEMS.map((item) => (
              <button
                key={item.label}
                onClick={() => handleNavClick(item.href)}
                className="text-[#DADADA] hover:text-white text-lg font-medium transition-colors"
              >
                {t.nav[item.label.toLowerCase() as keyof typeof t.nav]}
              </button>
            ))}
            <button 
              onClick={handleTalkToUs}
              className="bg-gradient-to-r from-purple-500 to-pink-500 text-white text-sm px-8 py-3 rounded-full flex items-center gap-2 hover:opacity-90 transition-opacity shadow-lg shadow-purple-500/25 mt-4"
            >
              <MessageSquare className="w-4 h-4" />
              {t.nav.talkToUs}
            </button>
          </div>
        </div>
      </motion.div>
    </>
  );
};