import Spline from '@splinetool/react-spline';

export const SplineBackground = () => {
  return (
    <div className="fixed inset-0 w-full h-full pointer-events-none z-0">
      <Spline
        scene={import.meta.env.VITE_SPLINE_SCENE_URL}
        className="w-full h-full"
      />
    </div>
  );
};