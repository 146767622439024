export const translations = {
  en: {
    nav: {
      services: 'Services',
      portfolio: 'Portfolio',
      about: 'About',
      testimonials: 'Testimonials',
      talkToUs: 'Talk to Us',
      contact: 'Contact'
    },
    hero: {
      title: 'No Code to Full Code.',
      subtitle: 'Your Shortcut to Scalable SaaS.',
      description: "Willow Wisp is your go-to full-stack agency. We craft no-code and full-code apps that empower startups to scale. With over 30 success stories, we're more than developers—we're your growth buddies.",
      cta: 'Talk to Us',
      companies: 'Worked with 30+ Companies'
    },
    flutterFlow: {
      title: 'From Visual Reference to FlutterFlow',
      description: 'Experience seamless transition from design to development. Our expertise in FlutterFlow enables rapid prototyping and development, turning your vision into reality with unprecedented speed and precision.',
      cta: 'Learn More'
    },
    testimonials: {
      title: 'What Our Clients Say',
      subtitle: "Hear from the companies we've helped grow and scale their applications"
    },
    services: {
      mvp: {
        title: 'MVP Design',
        description: 'We build your MVP from scratch—fast, lean, and ready to launch.'
      },
      noCode: {
        title: 'No Code Development',
        description: 'We craft custom no-code apps so you can scale at speed.'
      },
      fullStack: {
        title: 'Full-Stack Development',
        description: 'From concept to launch, we handle it all—front-end, back-end, and beyond.'
      },
      automation: {
        title: 'Automation',
        description: 'We automate workflows so your business can run on autopilot.'
      }
    },
    techStack: {
      title: 'Tools & Tech We Love',
      subtitle: 'We pick the right tools for the job—no fluff, all function.'
    },
    contact: {
      title: "Let's Build Something Great Together",
      subtitle: "Tell us about your project and we'll get back to you within 24 hours.",
      success: "Thank you! We'll get back to you soon.",
      error: "Something went wrong. Please try again later.",
      form: {
        name: "Name",
        email: "Email",
        message: "Message",
        submit: "Send Message",
        sending: "Sending..."
      }
    }
  },
  de: {
    nav: {
      services: 'Dienstleistungen',
      portfolio: 'Portfolio',
      about: 'Über uns',
      testimonials: 'Referenzen',
      talkToUs: 'Sprich mit uns',
      contact: 'Kontakt'
    },
    hero: {
      title: 'Von No-Code bis Full-Code.',
      subtitle: 'Dein Weg zur skalierbaren SaaS.',
      description: 'Willow Wisp ist deine Full-Stack-Agentur. Wir entwickeln No-Code- und Full-Code-Apps, die Startups beim Wachstum unterstützen. Mit über 30 Erfolgsgeschichten sind wir mehr als Entwickler – wir sind deine Wachstumspartner.',
      cta: 'Sprich mit uns',
      companies: 'Zusammenarbeit mit über 30 Unternehmen'
    },
    flutterFlow: {
      title: 'Vom visuellen Konzept zu FlutterFlow',
      description: 'Erlebe einen nahtlosen Übergang von Design zu Entwicklung. Unsere Expertise in FlutterFlow ermöglicht schnelle Prototypen und Entwicklung, die deine Vision mit beispielloser Geschwindigkeit und Präzision Realität werden lässt.'
    },
    testimonials: {
      title: 'Was unsere Kunden sagen',
      subtitle: 'Erfahren Sie von Unternehmen, denen wir beim Wachstum und der Skalierung ihrer Anwendungen geholfen haben'
    },
    services: {
      mvp: {
        title: 'MVP-Design',
        description: 'Wir entwickeln Ihren MVP von Grund auf – schnell, schlank und startbereit.'
      },
      noCode: {
        title: 'No-Code Entwicklung',
        description: 'Wir erstellen maßgeschneiderte No-Code-Apps für schnelles Wachstum.'
      },
      fullStack: {
        title: 'Full-Stack-Entwicklung',
        description: 'Von der Konzeption bis zum Launch übernehmen wir alles – Frontend, Backend und mehr.'
      },
      automation: {
        title: 'Automatisierung',
        description: 'Wir automatisieren Arbeitsabläufe, damit Ihr Unternehmen wie von selbst läuft.'
      }
    },
    techStack: {
      title: 'Tools & Technologien, die wir lieben',
      subtitle: 'Wir wählen die richtigen Werkzeuge für die Aufgabe – keine Spielereien, nur Funktion.'
    },
    contact: {
      title: "Lass uns gemeinsam etwas Großartiges schaffen",
      subtitle: "Erzähl uns von deinem Projekt und wir melden uns innerhalb von 24 Stunden bei dir.",
      success: "Danke! Wir melden uns bald bei dir.",
      error: "Etwas ist schiefgelaufen. Bitte versuch es später erneut.",
      form: {
        name: "Name",
        email: "E-Mail",
        message: "Nachricht",
        submit: "Nachricht senden",
        sending: "Wird gesendet..."
      }
    }
  },
  pt: {
    nav: {
      services: 'Serviços',
      portfolio: 'Portfólio',
      about: 'Sobre',
      testimonials: 'Depoimentos',
      talkToUs: 'Fale Conosco',
      contact: 'Contato'
    },
    hero: {
      title: 'De No-Code a Full-Code.',
      subtitle: 'Seu atalho para SaaS escalável.',
      description: 'A Willow Wisp é sua agência full-stack. Desenvolvemos apps no-code e full-code que capacitam startups a escalar. Com mais de 30 casos de sucesso, somos mais que desenvolvedores – somos seus parceiros de crescimento.',
      cta: 'Fale Conosco',
      companies: 'Trabalhou com mais de 30 empresas'
    },
    flutterFlow: {
      title: 'Da Referência Visual ao FlutterFlow',
      description: 'Experimente uma transição perfeita do design ao desenvolvimento. Nossa expertise em FlutterFlow permite prototipagem e desenvolvimento rápidos, transformando sua visão em realidade com velocidade e precisão sem precedentes.',
      cta: 'Saiba Mais'
    },
    testimonials: {
      title: 'O que nossos clientes dizem',
      subtitle: 'Ouça das empresas que ajudamos a crescer e escalar suas aplicações'
    },
    services: {
      mvp: {
        title: 'Design de MVP',
        description: 'Construímos seu MVP do zero – rápido, enxuto e pronto para lançar.'
      },
      noCode: {
        title: 'Desenvolvimento No-Code',
        description: 'Criamos apps no-code personalizados para você escalar rapidamente.'
      },
      fullStack: {
        title: 'Desenvolvimento Full-Stack',
        description: 'Do conceito ao lançamento, cuidamos de tudo – front-end, back-end e além.'
      },
      automation: {
        title: 'Automação',
        description: 'Automatizamos fluxos de trabalho para seu negócio funcionar no piloto automático.'
      }
    },
    techStack: {
      title: 'Ferramentas e Tecnologias que Amamos',
      subtitle: 'Escolhemos as ferramentas certas para o trabalho – sem frescura, só funcionalidade.'
    },
    contact: {
      title: "Vamos Criar Algo Incrível Juntos",
      subtitle: "Conta pra gente sobre seu projeto e retornaremos em até 24 horas.",
      success: "Valeu! Entraremos em contato em breve.",
      error: "Algo deu errado. Tenta novamente mais tarde.",
      form: {
        name: "Nome",
        email: "E-mail",
        message: "Mensagem",
        submit: "Enviar Mensagem",
        sending: "Enviando..."
      }
    }
  }
};