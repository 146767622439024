import { motion } from 'framer-motion';

interface FoundersProps {
  id?: string;
}

const founders = [
  {
    name: 'Michael',
    role: 'CEO & AI Specialist',
    image: '/images/michael.png'
  },
  {
    name: 'Momin',
    role: 'CTO & Technical Lead',
    image: '/images/momin.png'
  },
  {
    name: 'Zayn',
    role: 'Creative Director',
    image: '/images/zayn.png'
  }
];

export const Founders = ({ id }: FoundersProps) => {
  return (
    <section id={id} className="relative min-h-screen py-24 overflow-hidden scroll-mt-20">
      <div className="container mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="text-center mb-24"
        >
          <h2 className="text-4xl font-bold text-white mb-4">Meet the Innovators</h2>
          <p className="text-[#DADADA]">Powered by innovation, driven by excellence</p>
        </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-12 max-w-6xl mx-auto">
          {founders.map((founder, index) => (
            <motion.div
              key={founder.name}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: index * 0.2 }}
              className="relative group"
            >
              <motion.div
                initial={{ scale: 1.2, opacity: 0 }}
                whileInView={{ scale: 1, opacity: 1 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.3, duration: 0.6 }}
                className="relative aspect-square mb-24"
              >
                <img
                  src={founder.image}
                  alt={founder.name}
                  className="w-full h-full object-contain"
                />
              </motion.div>
              
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.4 }}
                className="absolute bottom-0 left-0 right-0 text-center"
              >
                <div className="backdrop-blur-sm p-4 min-h-[120px] flex flex-col justify-center">
                  <motion.h3
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    viewport={{ once: true }}
                    transition={{ delay: index * 0.5 }}
                    className="text-2xl font-bold text-white mb-2"
                  >
                    {founder.name}
                  </motion.h3>
                  <motion.p
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    viewport={{ once: true }}
                    transition={{ delay: index * 0.6 }}
                    className="text-purple-400 font-medium mb-1"
                  >
                    {founder.role}
                  </motion.p>
                </div>
              </motion.div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};