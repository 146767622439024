import { motion } from 'framer-motion';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';

interface TestimonialsProps {
  id?: string;
}

const testimonials = [
  {
    quote: "Michael is running multiple apps built in React and FlutterFlow himself, so you really feel undestood and its not the normal agency client relationship. Both parties are focusing on the market fit! Something I truly appreciate after years of experience in working with agencies. We will defintely keep working together guys!",
    author: "Rosa",
    role: "Founder Rosys Patterns",
    image: "https://rosyspatterns.com/cdn/shop/files/Rosa_db22f8cf-efae-476d-8fad-08929b680a33.webp?v=1728115044&width=1070"
  },
  {
    quote: "Their passion for AI integration is unmatched. The team not only implemented all our AI ideas but also brought innovative suggestions that transformed our product. They're true pioneers in merging AI with practical applications.",
    author: "Tim",
    role: "Co-Founder WAT",
    image: "https://pbs.twimg.com/profile_images/1208234904405757953/mT0cFOVQ_400x400.jpg"
  },
  {
    quote: "Working with Willow Wisp was a game-changer for our startup. Their technical expertise combined with business acumen helped us create a product that our users love.",
    author: "Julius",
    role: "Startup Founder",
    image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSZVpYxNR2jcjm_xqflOTzl-prQD4Yni-ipSg&s"
  }
];

export const Testimonials = ({ id }: TestimonialsProps) => {
  return (
    <section id={id} className="py-24 relative overflow-hidden scroll-mt-20">
      <div className="container mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="text-center mb-16"
        >
          <h2 className="text-3xl font-bold text-white mb-4">What Our Clients Say</h2>
          <p className="text-[#DADADA]">Hear from the innovators we've helped succeed</p>
        </motion.div>

        <div className="relative">
          <Swiper
            modules={[Pagination, Autoplay]}
            spaceBetween={32}
            slidesPerView={1}
            pagination={{ clickable: true }}
            autoplay={{ delay: 5000 }}
            className="testimonials-swiper !pb-12"
            breakpoints={{
              640: { slidesPerView: 2 },
              1024: { slidesPerView: 3 }
            }}
          >
            {testimonials.map((testimonial, index) => (
              <SwiperSlide key={index} className="h-auto">
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ delay: index * 0.1 }}
                  className="bg-white/5 backdrop-blur-lg rounded-2xl p-6 border border-white/10 h-full flex flex-col"
                  style={{ minHeight: '400px' }}
                >
                  <div className="flex items-center gap-4 mb-6">
                    <img
                      src={testimonial.image}
                      alt={testimonial.author}
                      className="w-12 h-12 rounded-full object-cover"
                    />
                    <div>
                      <h3 className="text-white font-semibold">{testimonial.author}</h3>
                      <p className="text-[#DADADA] text-sm">{testimonial.role}</p>
                    </div>
                  </div>
                  <p className="text-[#DADADA] text-sm leading-relaxed flex-grow">
                    "{testimonial.quote}"
                  </p>
                </motion.div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  );
};