import { Routes, Route } from 'react-router-dom';
import { Navbar } from './components/Navbar';
import { Hero } from './components/Hero';
import { Founders } from './components/Founders';
import { FlutterFlowShowcase } from './components/FlutterFlowShowcase';
import { Services } from './components/Services';
import { TechStack } from './components/TechStack';
import { Portfolio } from './components/Portfolio';
import { Testimonials } from './components/Testimonials';
import { SplineBackground } from './components/SplineBackground';
import { StickyContact } from './components/StickyContact';
import { TalkToUs } from './components/TalkToUs';

function App() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <main className="bg-[#0A0A0A] min-h-screen relative">
            <SplineBackground />
            <div className="relative z-10">
              <Navbar />
              <Hero />
              <Founders id="about" />
              <FlutterFlowShowcase />
              <Services id="services" />
              <TechStack />
              <Portfolio id="portfolio" />
              <Testimonials id="testimonials" />
              <StickyContact />
            </div>
          </main>
        }
      />
      <Route path="/talk-to-us" element={<TalkToUs />} />
    </Routes>
  );
}

export default App;